<script>
import Base from "@backend/Base.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import BOGen from "@helper/BOGen";
import Gen from "@helper/Gen";
import { UserIcon, CheckSquareIcon, SquareIcon } from "vue-feather-icons";

export default {
  name: "BoPPDB",
  extends: Base,
  components: {
    DatePicker,
    UserIcon,
    CheckSquareIcon,
    SquareIcon,
  },
  data() {
    return {
      Name: "BoPPDB",
      status: {},
      dataLength: 0,
      data: {},
      content: [],
      cluster: [],
      inputCluster: {
        acr_name: "",
        acr_date_start: "",
        acr_date_end: "",
        acr_id: "",
        acr_is_active: "",
      },
      inputPPDB: {},
      inputContent: {
        as_id: "",
        as_name: "",
        as_val: "",
        type: "",
      },
      row: {},
      isLoadingCluster: false,
      isLoadingPPDB: false,
    };
  },
  mounted() {
    this.$set(this.$root, "page", this);
    this.getData();
  },
  computed: {
    filterCluster: {
      get() {
        return this.filter.cluster;
      },
      set(newValue) {
        this.filter = {
          ...this.filter,
          cluster: newValue
        };
        this.search();
      }
    },
    startDate: {
      get: function() {
        return this.inputCluster.acr_date_start
          ? new Date(this.inputCluster.acr_date_start)
          : null;
      },
      set: function(newValue) {
        this.$set(this.inputCluster, "acr_date_start", `${newValue}`.dates("date"));
      },
    },
    endDate: {
      get: function() {
        return this.inputCluster.acr_date_end
          ? new Date(this.inputCluster.acr_date_end)
          : null;
      },
      set: function(newValue) {
        this.$set(this.inputCluster, "acr_date_end", `${newValue}`.dates("date"));
      },
    },
    sDateOfBirth: {
      get: function() {
        return this.row.ap_sdate_of_birth
          ? this.row.ap_sdate_of_birth.dates("format")
          : null;
      },
      set: function(newValue) {
        this.$set(this.row, "ap_sdate_of_birth", `${newValue}`.dates("date"));
      },
    },
    mDateOfBirth: {
      get: function() {
        return this.row.ap_mdate_of_birth
          ? this.row.ap_mdate_of_birth.dates("format")
          : null;
      },
      set: function(newValue) {
        this.$set(this.row, "ap_mdate_of_birth", `${newValue}`.dates("date"));
      },
    },
    fDateOfBirth: {
      get: function() {
        return this.row.ap_fdate_of_birth
          ? this.row.ap_fdate_of_birth.dates("format")
          : null;
      },
      set: function(newValue) {
        this.$set(this.row, "ap_fdate_of_birth", `${newValue}`.dates("date"));
      },
    },
    pDateOfBirth: {
      get: function() {
        return this.row.ap_pdate_of_birth
          ? this.row.ap_pdate_of_birth.dates("format")
          : null;
      },
      set: function(newValue) {
        this.$set(this.row, "ap_pdate_of_birth", `${newValue}`.dates("date"));
      },
    },
  },
  methods: {
    viewPPDB(v) {
      const { ap_id, ap_is_read } = v;
      if (ap_is_read === 'N') {
        const { data } = this.data;
        const index = data.findIndex((n) => n.ap_id === ap_id);
  
        if (index !== -1) {
          BOGen.apirest("/" + this.Name + "/" + ap_id);
          data[index] = {
            ...data[index],
            ap_is_read: 'Y'
          }
        }
      }
      this.$router.push({ name: this.Name, params: { id: v.ap_id } });
    },
    getData() {
      this.isLoadingCluster = true;
      this.isLoadingPPDB = true;
      this.refreshData(() => {
        this.isLoadingCluster = false;
        this.isLoadingPPDB = false;
      });
    },
    download() {
      window.location =
        this.baseUrl + "/result-ppdb/" + this.row.ap_code_registration;
    },
    sort() {
      this.filter.sort = this.filter.sort == "all" ? "" : "all";
      if (this.filter.sort == "all") {
        this.filter.cluster = "";
      }
      var query = this.filter;
      delete query.page;
      this.$router.push({
        name: this.Name,
        query: query,
      });
    },
    submitPPDB(e) {
      if (!this.moduleRole("Edit")) {
        return;
      }
      this.row.type = "updatePPDB";
      delete this.row.ap_code_registration;
      BOGen.apirest(
        "/" + this.Name,
        this.row,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
            }
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".ppdb-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".ppdb-info");
            this.refreshData();
          }
        },
        "POST"
      );
    },

    submitCluster(e) {
      BOGen.apirest(
        "/" + this.Name,
        this.inputCluster,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
            }
            if (err.status == 400) return swal(err.resp.message, "", "warning");
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".cluster-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".cluster-info");
            setTimeout(() => {
              $("#collapseGelombang").css("display", "none");
            }, 1000);
            this.refreshData();
          }
        },
        "POST"
      );
    },
    submitPPDBContent(e) {
      BOGen.apirest(
        "/" + this.Name,
        this.inputContent,
        (err, resp) => {
          if (e) e.btnUnloading();
          if (err) {
            if (err.status == 422) {
              Object.keys(err.responseJSON).forEach((k) => {
                var textError = err.responseJSON[k][0];
                if (textError == "validation.required")
                  textError = "Input Required";
                $("[error=" + k + "]").html(
                  "<label class='error'>" + textError + "</label>"
                );
              });
            }
            if (err.status == 400) return swal(err.resp.message, "", "warning");
            return;
          }
          if (resp.error)
            return Gen.info(resp.message, "danger", 3000, ".content-info");
          if (resp.success) {
            Gen.info(resp.message, "success", 2000, ".content-info");
            setTimeout(() => {
              $("#collapsePPDBContent").css("display", "none");
            }, 1000);
            this.refreshData();
          }
        },
        "POST"
      );
    },
    addCluster() {
      $(".fcbtn").html("Tambah")
      $("#collapseGelombang").css("display", "block");
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#collapseGelombang").offset().top - 150,
        },
        500
      );
      this.inputCluster = {
        acr_name: "",
        acr_date_start: "",
        acr_date_end: "",
        acr_id: "",
        acr_is_active: "N",
        type: "addCluster",
      };
    },
    editCluster(v) {
      $(".fcbtn").html("Ubah")
      $("#collapseGelombang").css("display", "block");
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#collapseGelombang").offset().top - 150,
        },
        500
      );
      Object.keys(v).forEach((key) => {
        this.inputCluster[key] = v[key];
      });
      this.inputCluster.type = "updateCluster";
    },
    editPPDBContent(v) {
      $("#collapsePPDBContent").css("display", "block");
      $([document.documentElement, document.body]).animate(
        {
          scrollTop: $("#collapsePPDBContent").offset().top - 150,
        },
        500
      );
      Object.keys(v).forEach((key) => {
        this.inputContent[key] = v[key];
      });
      this.inputContent.type = "updatePPDBContent";
    }
  },

  watch: {
    "$route.query"() {
      if (this.$route.params.id) {
        this.$set(this.inputPPDB, "page", this);
        setTimeout(() => {
          this.inputPPDB.page = this.$route.query.page;
        }, 500);
      }
      this.isLoadingPPDB = true;
      this.refreshData(() => {
        this.isLoadingPPDB = false;
      });
    },
    "filter.level"() {
      this.search();
    },
    "filter.status"() {
      this.search();
    },
    "startDate"(v) {
      if (new Date(v) > new Date(this.inputCluster.acr_date_end)) {
        this.inputCluster.acr_date_end = "";
      }
    },
    "inputCluster.acr_name"() {
      $("[name='acr_name']").valid();
    },
    "inputCluster.acr_date_start"(v) {
      if (v.length > 0) {
        $("[error='acr_date_start']").html("");
      }
    },
    "inputCluster.acr_date_end"(v) {
      if (v.length > 0) {
        $("[error='acr_date_end']").html("");
      }
    }
  },
};
</script>

<template>
  <div class="container-fluid">
    <PageTitle :no-add-item="true"></PageTitle>

    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <div class="row d-flex justify-content-between">
              <div class="col-md-6">
                <h5 class="card-title">Gelombang Pendaftaran</h5>
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <a
                  v-if="moduleRole('Add')"
                  href="javascript:;"
                  @click.prevent="addCluster"
                  class="btn btn-info btn-rounded"
                >
                  <i class="fas fa-plus-circle m-r-10"></i>Tambah Gelombang Registrasi
                </a>
              </div>
            </div>
          </div>
          <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    Nama
                  </th>
                  <th>Tanggal Mulai</th>
                  <th>Tanggal Selesai</th>
                  <th>Status</th>
                  <th class="text-center" width="120px">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, k) in cluster" :key="k" :id="'row' + k">
                  <td class="number">
                    {{ k + 1 }}
                  </td>
                  <td>{{ v.acr_name || "-" }}</td>
                  <td>{{ (v.acr_date_start || "").dates("format") || "-" }}</td>
                  <td>{{ (v.acr_date_end || "").dates("format") || "-" }}</td>
                  <td>
                    <label
                      v-if="v.acr_is_active == 'Y'"
                      class="label label-success"
                      >Active</label
                    >
                    <label
                      v-if="v.acr_is_active == 'N'"
                      class="label label-warning"
                      >Inactive</label
                    >
                  </td>
                  <td
                    class="btn-action"
                    v-if="
                      moduleRole('Edit') ||
                        moduleRole('Delete')
                    "
                  >
                    <a
                      v-if="page.moduleRole('Edit')"
                      href="javascript:;"
                      class="icon_action"
                      @click="editCluster(v)"
                      v-tooltip="'Ubah'"
                      ><i class="ti-marker-alt"></i
                    ></a>
                    <a
                      v-if="moduleRole('Edit')"
                      href="javascript:;"
                      class="icon_action"
                      @click="
                        changeStatusDynamic(
                          v,
                          'acr_is_active',
                          'acr_id',
                          'AppClusterRegister',
                          undefined,
                          'collapseGelombang'
                        )
                      "
                      v-tooltip="'Ubah Status'"
                      ><i class="icon-settings"></i
                    ></a>
                    <a
                      v-if="page.moduleRole('Delete') && v.acr_is_active === 'N'"
                      href="javascript:;"
                      class="icon_action"
                      @click="deleteItemId(v.acr_id, k, 'collapseGelombang')"
                      v-tooltip="'Hapus'"
                      ><i class="ti-trash"></i
                    ></a>
                  </td>
                </tr>
                <tr v-if="isLoadingCluster">
                  <td colspan="99">
                    <LoadingSpinner light></LoadingSpinner>
                  </td>
                </tr>
                <tr v-else-if="(cluster||[]).length == 0">
                  <td colspan="99">
                    <h5 class="tc">{{ NotFound }}</h5>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body collapse mt-4" id="collapseGelombang">
            <div class="card border">
              <div class="card-header">
                <h6 class="card-title">
                  {{ inputCluster.acr_id ? "Ubah" : "Tambah" }} Gelombang Pendaftaran
                </h6>
              </div>
              <VForm method="post" @resp="submitCluster">
                <div class="card-body">
                  <div class="cluster-info"></div>
                  <div>
                    <div class="row">
                      <div class="col-md-6 mb-2">
                        <BoField
                          class="mb-0"
                          name="acr_name"
                          mandatory
                          v-model="inputCluster.acr_name"
                          label="Nama Kegiatan"
                          :attr="{ placeholder: 'e.g. Gelombang I' }"
                        ></BoField>
                      </div>
                      <div class="col-md-6 mb-2">
                        <div class="row">
                          <BoField mandatory class="mb-0 col-lg-5" label="Tanggal Mulai">
                            <div>
                              <date-picker
                                placeholder="DD-MM-YYYY"
                                v-model="startDate"
                                :input-attr="{
                                  name: 'acr_date_start'
                                }"
                                type="date"
                                format="DD-MM-YYYY"
                                :disabled-date="(date) => date < new Date().setDate(new Date().getDate() - 1)"
                              ></date-picker>
                              <div error="acr_date_start"></div>
                            </div>
                          </BoField>
                          <div class="col-lg-2 mb-2 mtc">
                            <div class="span input-group-text p-1">
                              Sampai
                            </div>
                          </div>
                          <BoField mandatory label="Tanggal Selesai" class="mb-0 col-lg-5">
                            <div>
                              <date-picker
                                type="date"
                                format="DD-MM-YYYY"
                                v-model="endDate"
                                :input-attr="{
                                  name: 'acr_date_end'
                                }"
                                placeholder="DD-MM-YYYY"
                                :disabled-date="(date) => date < new Date(startDate)"
                              ></date-picker>
                              <div error="acr_date_end"></div>
                            </div>
                          </BoField>
                        </div>
                      </div>
                      <div class="col-md-12">
                        <BoField name="acr_is_active" :label="'Status'">
                          <radio
                            name="acr_is_active"
                            v-model="inputCluster.acr_is_active"
                            option="Y"
                            :attr="validation('acr_is_active')"
                            >Active</radio
                          >
                          <radio
                            name="acr_is_active"
                            v-model="inputCluster.acr_is_active"
                            option="N"
                            >Inactive
                          </radio>
                        </BoField>
                      </div>
                      <div class="col-sm-12">
                        <div class="text-right">
                          <button
                            type="submit"
                            class="fcbtn btn btn-info btn-outline btn-1e btn-loading btn-rounded"
                          >
                            {{ (inputCluster.acr_id ? "Ubah" : "Tambah") }}
                            <i class="icon-arrow-right14 position-right"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </VForm>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
    <div class="row" v-if="!$route.params.id">
      <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        <div class="card">
          <div class="card-body">
            <VForm @resp="search">
              <div class="row gutter-10">
                <div class="col-sm-5">
                  <h5 class="card-title">{{ ObjectName }} List</h5>
                </div>
                <div v-if="dataLength > 0" class="col-sm-auto ml-auto">
                  <select2 v-model="filterCluster" class="mw-select">
                    <option value="">-- Pilih Gelombang --</option>
                    <option
                      v-for="(c, k) in clusterRegistered"
                      :key="k"
                      :value="c.acr_id"
                    >
                      {{ c.acr_name }}
                    </option>
                  </select2>
                </div>
                <div v-if="dataLength > 0" class="col-sm-3">
                  <div class="form-group mb-0">
                    <div class="input-group">
                      <input
                        type="text"
                        v-model="filter.search"
                        v-on:keyup.enter="search"
                        class="form-control"
                        placeholder="Search..."
                      />
                      <div class="input-group-append">
                        <button
                          class="btn btn-info"
                          type="button"
                          @click="search()"
                        >
                          <i class="fas fa-search"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="dataLength > 0" class="col-3 col-sm-1">
                  <router-link
                    :to="{ name: Name }"
                    class="btn btn-block btn-warning"
                    >Reset</router-link
                  >
                </div>
              </div>
            </VForm>
          </div>
          <div class="table-responsive ajax-table">
            <table class="table table-striped table-bordered">
              <thead>
                <tr>
                  <th>#</th>
                  <th>
                    Nama
                  </th>
                  <th>
                    Gelombang Pendaftaran
                  </th>
                  <th>Email Orang Tua</th>
                  <th>Kelas</th>
                  <th>Tanggal Register</th>
                  <th>Status</th>
                  <th class="text-center" width="120px">Aksi</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(v, k) in data.data" :key="k">
                  <td class="number">
                    {{ data.per_page * (data.current_page - 1) + k + 1 }}
                  </td>
                  <td>{{ v.ap_sname || "-" }}</td>
                  <td>{{ v.acr_name || "-" }}</td>
                  <td>{{ v.ap_memail || "-" }}</td>
                  <td>{{ v.ap_sclass_register || "-" }}</td>
                  <td>{{ (v.ap_create_at || "").dates("format") || "-" }}</td>
                  <td>
                    <label
                      v-if="v.ap_is_read == 'Y'"
                      class="label label-success"
                      >Read</label
                    >
                    <label
                      v-if="v.ap_is_read == 'N'"
                      class="label label-warning"
                      >Unread</label
                    >
                  </td>
                  <td class="btn-action text-center">
                    <a
                      class="icon_action"
                      href="javacript:;"
                      @click.prevent="viewPPDB(v)"
                      v-tooltip="'Lihat'"
                      ><i class="ti-eye"></i
                    ></a>
                  </td>
                </tr>
                <tr v-if="NotFound">
                  <td colspan="99">
                    <h5 class="tc">{{ NotFound }}</h5>
                  </td>
                </tr>
                <tr v-if="isLoadingPPDB">
                  <td colspan="99">
                    <LoadingSpinner light></LoadingSpinner>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="card-body">
            <Pagination
              class="justify-content-end m-0"
              :data="data"
              :limit="3"
              @pagination-change-page="onPaging"
            ></Pagination>
          </div>
        </div>
      </div>
    </div>

    <!-- ======================================================================== 
			Detail Registration
		============================================================================= -->
    <div class="card" v-if="$route.params.id">
      <div class="card-body">
        <div class="row mb-3">
          <div class="col-md-12 d-flex justify-content-between">
            <h5 class="card-title">
              {{ ObjectName + " " + "Detail" }}
            </h5>
            <button
              href="javascript:;"
              @click="download"
              class="btn btn-warning btn-rounded"
              type="button"
            >
              <i class="fas fa-arrow-down m-r-10"></i>Download Data Diri
            </button>
          </div>
        </div>
        <div class="ppdb-info"></div>
          <VForm @resp="submitPPDB">
            <div class="col-sm-12">
              <table class="table table-bordered">
                <tbody>
                  <tr>
                    <td colspan="10" class="main table-active">
                      <user-icon></user-icon>
                      <strong class="ml-2 align-middle">DATA SISWA/I</strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Nama Lengkap</td>
                    <td class="data">
                      {{row.ap_sname}}
                    </td>
                    <td rowspan="6" class="data-img" width="240px">
                      <img :src="uploader(row.ap_sphoto)" />
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Jenis Kelamin</td>
                    <td class="data">
                      {{row.ap_sgender}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Tempat, Tanggal Lahir</td>
                    <td class="data">
                      {{sDateOfBirth ? row.ap_splace_of_birth + ", " + (sDateOfBirth) : "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Alamat</td>
                    <td class="data">
                      {{row.ap_saddress}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Mendaftar ke Kelas</td>
                    <td class="data">
                      {{row.ap_sclass_register}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">TK Asal</td>
                    <td class="data">
                      {{row.ap_stk || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Warga Negara</td>
                    <td class="data" colspan="2">
                      {{row.ap_scitizenship || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Agama</td>
                    <td class="data" colspan="2">
                      {{row.ap_sreligion || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Status dalam Keluarga</td>
                    <td class="data" colspan="2">
                      {{row.ap_sfamily_status || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Anak ke-</td>
                    <td class="data" colspan="2">
                      {{row.ap_snth_child || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Dokumen</td>
                    <td class="data" colspan="2">
                      : Akta Kelahiran
                      <check-square-icon v-if="row.ap_sakta"></check-square-icon
                      ><square-icon v-else></square-icon> | Kartu Keluarga
                      <check-square-icon v-if="row.ap_skk"></check-square-icon
                      ><square-icon v-else></square-icon> | Pas Foto
                      <check-square-icon
                        v-if="row.ap_sphoto"
                      ></check-square-icon
                      ><square-icon v-else></square-icon> | Ijazah TK
                      <check-square-icon
                        v-if="row.ap_stk_ijazah"
                      ></check-square-icon
                      ><square-icon v-else></square-icon> | Tes Psikotes
                      <check-square-icon
                        v-if="row.ap_stk_psikotest"
                      ></check-square-icon
                      ><square-icon v-else></square-icon>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="10" class="main table-active">
                      <user-icon></user-icon>
                      <strong class="ml-2 align-middle">DATA IBU</strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Nama Lengkap</td>
                    <td class="data" colspan="2">
                      {{row.ap_mname || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Tempat, Tanggal Lahir</td>
                    <td class="data" colspan="2">
                      {{mDateOfBirth ? row.ap_mplace_of_birth +", "+(mDateOfBirth) : "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Alamat Tempat Tinggal</td>
                    <td class="data" colspan="2">
                      {{row.ap_maddress || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">No. HP / Telepon</td>
                    <td class="data" colspan="2">
                      {{row.ap_mphone || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Pekerjaan</td>
                    <td class="data" colspan="2">
                      {{row.ap_mjob || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Alamat Kantor</td>
                    <td class="data" colspan="2">
                      {{row.ap_mjob_address || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Agama</td>
                    <td class="data" colspan="2">
                      {{row.ap_mreligion || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Pendidikan Terakhir</td>
                    <td class="data" colspan="2">
                      {{row.ap_mlast_education || "-"}}
                    </td>
                  </tr>

                  <tr>
                    <td colspan="10" class="main table-active">
                      <user-icon></user-icon>
                      <strong class="ml-2 align-middle">DATA AYAH</strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Nama Lengkap</td>
                    <td class="data" colspan="2">
                      {{row.ap_fname || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Tempat, Tanggal Lahir</td>
                    <td class="data" colspan="2">
                      {{fDateOfBirth ? row.ap_fplace_of_birth + ", " + (fDateOfBirth) : '-'}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Alamat Tempat Tinggal</td>
                    <td class="data" colspan="2">
                      {{row.ap_faddress || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">No. HP / Telepon</td>
                    <td class="data" colspan="2">
                      {{row.ap_fphone || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Pekerjaan</td>
                    <td class="data" colspan="2">
                      {{row.ap_fjob || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Alamat Kantor</td>
                    <td class="data" colspan="2">
                      {{row.ap_fjob_address || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Agama</td>
                    <td class="data" colspan="2">
                      {{row.ap_freligion || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Pendidikan Terakhir</td>
                    <td class="data" colspan="2">
                      {{row.ap_flast_education || "-"}}
                    </td>
                  </tr>

                  <tr>
                    <td colspan="10" class="main table-active">
                      <user-icon></user-icon>
                      <strong class="ml-2 align-middle">DATA WALI</strong>
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Nama Lengkap</td>
                    <td class="data" colspan="3">
                      {{row.ap_pname || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Tempat, Tanggal Lahir</td>
                    <td class="data" colspan="3">
                      {{pDateOfBirth ? row.ap_pplace_of_birth + "," + (pDateOfBirth) : '-'}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Alamat Tempat Tinggal</td>
                    <td class="data" colspan="3">
                      {{row.ap_paddress || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">No. HP / Telepon</td>
                    <td class="data" colspan="3">
                      {{row.ap_pphone || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Pekerjaan</td>
                    <td class="data" colspan="3">
                      {{row.ap_pjob || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Alamat Kantor</td>
                    <td class="data" colspan="3">
                      {{row.ap_fjob_address || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Agama</td>
                    <td class="data" colspan="3">
                      {{row.ap_preligion || "-"}}
                    </td>
                  </tr>
                  <tr>
                    <td width="250px">Pendidikan Terakhir</td>
                    <td class="data" colspan="3">
                      {{row.ap_flast_education || "-"}}
                    </td>
                  </tr>
                  <tr class="main table-active">
                    <td width="250px"><b>Email Ayah/Ibu</b></td>
                    <td class="data" colspan="2">
                      {{row.ap_memail || "-"}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-12">
              <div class="row">
                <div class="card">
                  <div class="card-header">
                    <h6 class="card-title">Dokumen</h6>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-3 mb-3" v-if="row.ap_sakta">
                        <BoField name="sakta" label="Akta Kelahiran">
                          <img :src="uploader(row.ap_sakta)" />
                        </BoField>
                      </div>
                      <div class="col-md-3 mb-3" v-if="row.ap_skk">
                        <BoField name="skk" label="Kartu Keluarga">
                          <img :src="uploader(row.ap_skk)" />
                        </BoField>
                      </div>
                      <div class="col-md-3 mb-3" v-if="row.ap_stk_ijazah">
                        <BoField name="stk_ijazah" label="Ijazah TK">
                          <img :src="uploader(row.ap_stk_ijazah)" />
                        </BoField>
                      </div>
                      <div class="col-md-3 mb-3" v-if="row.ap_stk_psikotest">
                        <BoField name="stk_psikotest" label="Hasil Psikotest">
                          <img :src="uploader(row.ap_stk_psikotest)">
                        </BoField>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </VForm>
        </div>
    </div>
    
    <div class="card" v-if="!$route.params.id">
      <div class="card-body">
        <div class="row d-flex justify-content-between">
          <div class="col-md-6">
            <h5 class="card-title">Konten Registrasi</h5>
          </div>
        </div>
      </div>
      <div class="table-responsive ajax-table">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th>#</th>
              <th>
                Judul
              </th>
              <th class="text-center" width="120px">Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(v, k) in content" :key="k" :id="'row' + k">
              <td class="number">
                {{ k + 1 }}
              </td>
              <td>{{ v.as_name || "-" }}</td>
              <td
                class="btn-action text-center"
                v-if="
                  moduleRole('Edit') ||
                    moduleRole('Delete')
                "
              >
                <a
                  v-if="page.moduleRole('Edit')"
                  href="javascript:;"
                  class="icon_action"
                  @click="editPPDBContent(v)"
                  v-tooltip="'Ubah'"
                  ><i class="ti-marker-alt"></i
                ></a>
              </td>
            </tr>
            <tr v-if="isLoadingCluster">
              <td colspan="99">
                <LoadingSpinner light></LoadingSpinner>
              </td>
            </tr>
            <tr v-else-if="(content||[]).length == 0">
              <td colspan="99">
                <h5 class="tc">{{ NotFound }}</h5>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="card-body collapse mt-4" id="collapsePPDBContent">
        <div class="card border">
          <div class="card-header">
            <h6 class="card-title">
              Ubah Konten Registrasi
            </h6>
          </div>
          <VForm @resp="submitPPDBContent" method="post">
            <div class="card-body">
              <div class="content-info"></div>
              <div class="row">
                <div class="col-sm-12">
                  <BoField 
                    name="as_name" 
                    :label="'Judul'" 
                    v-model="inputContent.as_name" 
                    :attr="{
                      placeholder: 'e.g. Persyaratan dan Dokumen',
                      readonly: 'readonly'
                    }"
                    v-bind="validation('as_name')"
                    keyFilter="alphaOnly"
                    mandatory
                  ></BoField>
                  <BoField name="as_val" :label="'Deskripsi'" mandatory>
                    <CKEditor 
                      name="as_val" 
                      v-model="inputContent.as_val" 
                      v-bind="validation('as_val')"
                    ></CKEditor>
                  </BoField>
                </div>
                <div class="col-12">
                  <div class="text-right">
                    <button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">Ubah</button>
                  </div>
                </div>
              </div>
            </div>
          </VForm>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
  .mtc {
    margin-top: 5.5%;
  }
  .mtc .span{
    display: flex;
    justify-content : center;
  }
  @media(max-width: 768px){
    .mtc {
      margin-top: 0%;
    }  
  }
</style>
